<template>
  <div style="font-size: 10px">
    <van-button
      v-if="showButton"
      type="info"
      :style="{ width: width }"
      style="height: 50px; font-size: 20px"
      native-type="button"
      @click="confirmLocation"
    >
      确&nbsp;定
    </van-button>
    <baidu-map
      v-if="mapRender"
      :style="{ width: width, height: height }"
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      ak="kbAfqDl8MTGTZAZpK7FQrVj11OyHT3Q4"
      @ready="mapInit"
      @click="getLocation"
    >
      <bm-marker
        v-for="(item, index) in markers"
        :key="index"
        :position="{ lng: item.lng, lat: item.lat }"
      />
      <bm-overlay
        v-if="center.mapType === 3"
        pane="markerPane"
        style="width: 30px; height: 60px; position: absolute; overflow: hidden"
        @initialize="drawMerchant"
        @draw="drawMerchant"
      >
        <svg height="60" width="30">
          <circle cx="15" cy="13" r="12" style="fill: red" />
          <circle cx="10" cy="10" r="3" style="fill: white" />
          <rect x="13" y="25" width="4" height="30" style="fill: gray" />
        </svg>
      </bm-overlay>
      <bm-overlay
        v-if="center.mapType === 3"
        pane="markerPane"
        style="width: 60px; height: 60px; position: absolute; overflow: hidden"
        @initialize="drawUser"
        @draw="drawUser"
      >
        <svg height="60" width="60">
          <circle
            cx="30"
            cy="30"
            r="20"
            style="
              fill: rgb(235, 243, 255, 0.3);
              stroke: rgb(89, 156, 255, 0.8);
              stroke-width: 2;
            "
          />
          <circle cx="30" cy="30" r="7" style="fill: rgb(17, 148, 255)" />
        </svg>
      </bm-overlay>
    </baidu-map>
  </div>
</template>

<script>
  import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
  import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
  import BmOverlay from 'vue-baidu-map/components/overlays/Overlay.vue'

  export default {
    name: 'BaiduMapView',
    components: { BaiduMap, BmMarker, BmOverlay },
    props: {
      mapData: {
        type: Object,
        // 对象或数组默认值必须从一个工厂函数获取
        default: function() {
          return {
            data: [{ lng: 121.161162, lat: 30.043746 }],
            mapType: 1,
            showButton: false,
            width: '100%',
            height: '50px',
          }
        },
      },
    },
    data() {
      return {
        center: { lng: 0, lat: 0, mapType: 1 },
        zoom: 18,
        markers: [],
        userMarker: { lng: 0, lat: 0 },
        merchantMarker: { lng: 0, lat: 0 },
        showButton: true,
        width: '100%',
        height: '50px',
        map: null,
        mapRender: true,
        x_pi: (3.14159265358979324 * 3000.0) / 180.0,
      }
    },
    created() {
      if (this.mapData.showButton === undefined) {
        this.showButton = false
      } else {
        this.showButton = this.mapData.showButton
      }

      if (this.mapData.width === undefined) {
        this.width = '100%'
      } else {
        this.width = this.mapData.width
      }

      if (this.mapData.height === undefined) {
        if (this.showButton) {
          this.height = window.innerHeight - 50 + 'px'
        } else {
          this.height = window.innerHeight + 'px'
        }
      } else {
        this.height = this.mapData.height
      }
    },
    methods: {
      mapInit({ BMap, map }) {
        this.center.lng = this.mapData.data[0].lng
        this.center.lat = this.mapData.data[0].lat
        this.center.mapType = this.mapData.mapType
        this.zoom = 18

        if (this.mapData.mapType === 3) {
          this.userMarker.lng = this.mapData.data[0].lng
          this.userMarker.lat = this.mapData.data[0].lat
          this.merchantMarker.lng = this.mapData.data[1].lng
          this.merchantMarker.lat = this.mapData.data[1].lat
        } else {
          this.markers.push(this.center)
        }
      },
      render() {
        this.mapRender = false

        this.$nextTick(() => {
          this.mapRender = true
        })
      },
      getLocation({ type, target, point, pixel, overlay }) {
        if (this.center.mapType === 1) {
          const position = { lng: point.lng, lat: point.lat }
          this.markers.pop()
          this.markers.push(position)
          this.center.lat = point.lat
          this.center.lng = point.lng
        }
      },
      confirmLocation() {
        this.$emit('confirmEvent', {
          lat: this.center.lat,
          lng: this.center.lng,
        })
      },
      drawMerchant({ el, BMap, map }) {
        const pixel = map.pointToOverlayPixel(
          new BMap.Point(this.merchantMarker.lng, this.merchantMarker.lat)
        )
        el.style.left = pixel.x - 13 + 'px'
        el.style.top = pixel.y - 56 + 'px'
      },
      drawUser({ el, BMap, map }) {
        const pixel = map.pointToOverlayPixel(
          new BMap.Point(this.userMarker.lng, this.userMarker.lat)
        )
        el.style.left = pixel.x - 30 + 'px'
        el.style.top = pixel.y - 30 + 'px'
      },
      fD(a, b, c) {
        for (; a > c; ) a -= c - b
        for (; a < b; ) a += c - b
        return a
      },
      jD(a, b, c) {
        b != null && (a = Math.max(a, b))
        c != null && (a = Math.min(a, c))
        return a
      },
      yk(a) {
        return (Math.PI * a) / 180
      },
      Ce(a, b, c, d) {
        const dO = 6370996.81
        return (
          dO *
          Math.acos(
            Math.sin(c) * Math.sin(d) +
              Math.cos(c) * Math.cos(d) * Math.cos(b - a)
          )
        )
      },
      //计算距离 单位：米
      getDistance(a, b) {
        if (!a || !b) return 0
        a.lng = this.fD(a.lng, -180, 180)
        a.lat = this.jD(a.lat, -74, 74)
        b.lng = this.fD(b.lng, -180, 180)
        b.lat = this.jD(b.lat, -74, 74)
        return this.Ce(
          this.yk(a.lng),
          this.yk(b.lng),
          this.yk(a.lat),
          this.yk(b.lat)
        )
      },
      /// <summary>
      /// 中国正常坐标系GCJ02协议的坐标，转到 百度地图对应的 BD09 协议坐标
      ///  point 为传入的对象，例如{lat:xxxxx,lng:xxxxx}
      /// google地图坐标转换成百度地图坐标
      /// </summary>
      Convert_GCJ02_To_BD09(point) {
        let x = point.lng,
          y = point.lat
        let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi)
        let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi)
        point.lng = z * Math.cos(theta) + 0.0065
        point.lat = z * Math.sin(theta) + 0.006
        return point
      },
      /// <summary>
      /// 百度地图对应的 BD09 协议坐标，转到 中国正常坐标系GCJ02协议的坐标
      /// 百度地图坐标转换成google地图坐标
      /// </summary>
      Convert_BD09_To_GCJ02(point) {
        let x = point.lng - 0.0065,
          y = point.lat - 0.006
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi)
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi)
        point.lng = z * Math.cos(theta)
        point.lat = z * Math.sin(theta)
      },
    },
  }
</script>

<style scoped></style>
